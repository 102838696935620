export const orderArticlesAlphabeticallyByTitle = (
  firstElem,
  secondElem
): number => {
  let orderNo = 0;
  if (firstElem.title > secondElem.title) {
    orderNo = 1;
  } else if (secondElem.title > firstElem.title) {
    orderNo = -1;
  }
  return orderNo;
};

export const orderArticlesChronologicallyToOldest = (
  firstElem,
  secondElem
): number => {
  let orderNo = 0;
  if (parseInt(firstElem.publishedDate) < parseInt(secondElem.publishedDate)) {
    orderNo = 1;
  } else if (
    parseInt(secondElem.publishedDate) < parseInt(firstElem.publishedDate)
  ) {
    orderNo = -1;
  }
  return orderNo;
};

export const orderArticlesChronologicallyToNewest = (
  firstElem,
  secondElem
): number => {
  let orderNo = 0;
  if (parseInt(firstElem.updatedAt) > parseInt(secondElem.updatedAt)) {
    orderNo = 1;
  } else if (parseInt(secondElem.updatedAt) > parseInt(firstElem.updatedAt)) {
    orderNo = -1;
  }
  return orderNo;
};

export const sortArticleByCount = (allArticles, articleCountMap) => {
  return {
    ...allArticles,
    edges: allArticles.edges.slice().sort((article1, article2) => {
      const article1Count = articleCountMap[article1.node.id] || 0;
      const article2Count = articleCountMap[article2.node.id] || 0;

      return article2Count - article1Count;
    }),
  };
};
