import React, { FunctionComponent, useMemo } from 'react';

import './Home.scss';
import { Visible, Hidden } from 'react-grid-system';
import { PrimaryLayout } from 'components/PrimaryLayout';
import { useAllContentfulArticle } from '../../lib/queries/article.queries';
import { useAllContentfulCategory } from '../../lib/queries/categories.queries';
import { GridView } from 'components/Views/GridView';
import { ListView } from 'components/Views/ListView';
import { SideArticleList } from 'components/SideArticleList';
import { SocialCard } from 'components/SocialCard';
import { sortArticleByCount } from 'utils/helpers';

type HomeProps = {
  pageContext: {
    articleAllTimeViewCountMap: {
      [articleId: string]: number;
    };
    articleMonthlyViewCountMap: {
      [articleId: string]: number;
    };
  };
};
const Home: FunctionComponent<HomeProps> = ({ pageContext }) => {
  const { articleAllTimeViewCountMap, articleMonthlyViewCountMap } =
    pageContext;

  const allContentfulArticle = useAllContentfulArticle();
  const allContentfulCategory = useAllContentfulCategory();
  const categoryIDsToBeFeatured: string[] = [];

  allContentfulArticle.edges.forEach((el) => {
    if (
      el.node.subcategory.category.featuredOnHomePage.includes('Yes') &&
      !categoryIDsToBeFeatured.includes(el.node.subcategory.category.id)
    ) {
      categoryIDsToBeFeatured.push(el.node.subcategory.category.id);
    }
  });

  const findArticlesOfCategory = (categoryId: string): string => {
    return allContentfulArticle.edges.filter(
      (el) => el.node.subcategory.category.id === categoryId
    );
  };

  const findViewTypeOfCategory = (
    categoryId: string
  ): { viewType: string; sectionMarkerColor } => {
    const categories: { viewType: string; sectionMarkerColor }[] = [];
    allContentfulCategory.edges.forEach((el) => {
      if (el.node.id === categoryId) {
        categories.push({
          viewType: el.node.featuredOnHomePage,
          sectionMarkerColor: el.node.sectionMarkerColor,
        });
      }
    });
    return categories[0];
  };

  const MobileBreakComponents: FunctionComponent = () => {
    return (
      <Visible md sm xs>
        <div className="social-small-homepage-container">
          <SocialCard />
        </div>
        <div
          className="side-article-small-homepage-container"
          id="mobile-break"
        >
          <SideArticleList
            title="Popular"
            icon="categories-title-prefix"
            allContentfulArticle={allContentfulArticle}
          />
        </div>
      </Visible>
    );
  };

  const ViewConstructor: FunctionComponent<{
    id: string;
    index: number;
    viewType: 'list' | 'grid';
  }> = ({ id, index, viewType }) => {
    const articles = findArticlesOfCategory(id);
    const type = findViewTypeOfCategory(id);
    const args = {
      sectionMarkerColor: type.sectionMarkerColor,
      allContentfulArticle: { edges: articles },
    };

    return viewType === 'list' ? (
      <>
        <ListView {...args} />
        {index === 1 && <MobileBreakComponents />}
      </>
    ) : (
      <>
        <GridView {...args} />
        {index === 1 && <MobileBreakComponents />}
      </>
    );
  };

  const popularArticles = useMemo(() => {
    return sortArticleByCount(allContentfulArticle, articleMonthlyViewCountMap);
  }, [allContentfulArticle, articleMonthlyViewCountMap]);

  const topArticles = useMemo(() => {
    return sortArticleByCount(allContentfulArticle, articleAllTimeViewCountMap);
  }, [allContentfulArticle, articleAllTimeViewCountMap]);

  const viewTypes = ['list', 'grid'] as const;

  return (
    <PrimaryLayout>
      <div className="outer-home-container">
        <div style={{ padding: 0 }} className="home-container">
          <div className="home-column">
            {/* Once popularity is implemented, most popular articles will be given to this GridView component as data */}
            <GridView
              includeSectionHeader={false}
              allContentfulArticle={popularArticles}
            />
            <div className="side-article-small-homepage-container">
              <Visible md sm xs>
                <SideArticleList
                  title="Trending"
                  icon="categories-link-prefix"
                  allContentfulArticle={allContentfulArticle}
                />
              </Visible>
            </div>
            {categoryIDsToBeFeatured.map((el, index) => {
              return (
                <Hidden key={el}>
                  <ViewConstructor
                    id={el}
                    index={index}
                    viewType={viewTypes[index % 2]}
                  />
                </Hidden>
              );
            })}
          </div>
          <div className="home-column">
            <Hidden md sm xs>
              <SocialCard />
              <SideArticleList
                title="Top Stories"
                icon="categories-link-prefix"
                allContentfulArticle={topArticles}
              />
              <SideArticleList
                title="Popular"
                icon="categories-title-prefix"
                allContentfulArticle={popularArticles}
              />
            </Hidden>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default Home;
