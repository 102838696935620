import React, { FunctionComponent } from 'react';

import { PrimaryArticleHorizontal } from '../../PrimaryArticleHorizontal';
import { SectionHeaderPrimary } from '../../SectionHeaderPrimary';
import { TertiaryArticleHorizontal } from '../../TertiaryArticleHorizontal';
import { Col, Row, Container, Visible, Hidden } from 'react-grid-system';
import { SectionMarkerProps } from '../../SectionMarker';
import * as routes from '../../../../utils/routes';
export type ListViewProps = {
  allContentfulArticle;
  includeSectionHeader?: boolean;
  sectionMarkerColor: SectionMarkerProps['brandColor'];
};
const ListView: FunctionComponent<ListViewProps> = ({
  allContentfulArticle,
  includeSectionHeader = true,
  sectionMarkerColor,
}) => {
  return (
    <Container style={{ padding: 0, marginTop: 30 }}>
      {includeSectionHeader && (
        <SectionHeaderPrimary
          description={
            allContentfulArticle.edges[0].node.subcategory.category.teaserText
              .teaserText
          }
          sectionMarkerColor={sectionMarkerColor}
          title={allContentfulArticle.edges[0].node.subcategory.category.name}
          seeMoreLink={`${routes.SiteRoutes.CATEGORIES}/${allContentfulArticle.edges[0].node.subcategory.category.slug}`}
        />
      )}
      <Row style={{ marginTop: 30, padding: 0, margin: '30px 0 0  0' }}>
        {allContentfulArticle.edges.slice(0, 4).map(({ node }) => {
          const { id, title, slug, subtitle, thumbnailBam, subcategory } = node;

          return (
            <Hidden key={id}>
              <Hidden sm xs>
                <Col sm={12} style={{ marginBottom: 30, padding: 0 }}>
                  <PrimaryArticleHorizontal
                    image={thumbnailBam}
                    categoryLabel={subcategory.name}
                    header={title}
                    categoryLink={`${subcategory.category.slug}/${subcategory.slug}`}
                    articleLink={slug}
                    articleText={subtitle || ''}
                    isSponsored={false}
                  />
                </Col>
              </Hidden>
              <Visible sm xs>
                <Col sm={12} style={{ marginBottom: 30, padding: 0 }}>
                  <TertiaryArticleHorizontal
                    image={thumbnailBam}
                    categoryLabel={subcategory.name}
                    header={title}
                    categoryLink={`${subcategory.category.slug}/${subcategory.slug}`}
                    articleLink={slug}
                    isSponsored={false}
                  />
                </Col>
              </Visible>
            </Hidden>
          );
        })}
      </Row>
    </Container>
  );
};

export default ListView;
