import React, { FunctionComponent } from 'react';
import { FeatureArticle } from '../../FeatureArticle';
import { TertiaryArticle } from '../../TertiaryArticle';
import { TertiaryArticleHorizontal } from '../../TertiaryArticleHorizontal';
import { SectionMarkerProps } from '../../SectionMarker';
import { Col, Row, Container, Visible, Hidden } from 'react-grid-system';
import { SectionHeaderPrimary } from '../../SectionHeaderPrimary';
import * as routes from '../../../../utils/routes';
import './GridView.scss';
export type GridViewProps = {
  allContentfulArticle;
  includeSectionHeader?: boolean;
  sectionMarkerColor?: SectionMarkerProps['brandColor'];
};
const GridView: FunctionComponent<GridViewProps> = ({
  allContentfulArticle,
  includeSectionHeader = true,
  sectionMarkerColor,
}) => {
  return (
    <Container className="grid-view-container" style={{ padding: 0 }}>
      {includeSectionHeader && (
        <SectionHeaderPrimary
          description={
            allContentfulArticle.edges[0].node.subcategory.category.teaserText
              .teaserText
          }
          sectionMarkerColor={sectionMarkerColor}
          title={allContentfulArticle.edges[0].node.subcategory.category.name}
          seeMoreLink={`${routes.SiteRoutes.CATEGORIES}/${allContentfulArticle.edges[0].node.subcategory.category.slug}`}
        />
      )}
      <Row
        style={{
          padding: 0,
          margin: includeSectionHeader ? '30px 0 0  0' : '0 0 30px  0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {allContentfulArticle.edges.slice(0, 4).map(({ node }, index) => {
          const { id, title, slug, thumbnailBam, subcategory } = node;

          return index === 0 ? (
            <Hidden key={id}>
              <Hidden sm xs>
                <Col sm={12} style={{ marginBottom: 30, padding: 0 }}>
                  <FeatureArticle
                    articleLink={slug}
                    categoryLabel={subcategory.name}
                    header={title}
                    categoryLink={`${subcategory.category.slug}/${subcategory.slug}`}
                    image={thumbnailBam}
                  />
                </Col>
              </Hidden>
              <Visible sm xs>
                {includeSectionHeader ? (
                  <Col sm={12} style={{ marginBottom: 30, padding: 0 }}>
                    <TertiaryArticleHorizontal
                      articleLink={slug}
                      header={title}
                      categoryLabel={subcategory.name}
                      categoryLink={`${subcategory.category.slug}/${subcategory.slug}`}
                      image={thumbnailBam}
                    />
                  </Col>
                ) : (
                  <Col sm={12} style={{ marginBottom: 30, padding: 0 }}>
                    <FeatureArticle
                      articleLink={slug}
                      categoryLabel={subcategory.name}
                      header={title}
                      categoryLink={`${subcategory.category.slug}/${subcategory.slug}`}
                      image={thumbnailBam}
                    />
                  </Col>
                )}
              </Visible>
            </Hidden>
          ) : (
            <Hidden key={id}>
              <Hidden sm xs>
                <Col
                  md={4}
                  style={{
                    margin: '0 0',
                    padding: 0,
                    maxWidth: '32%',
                  }}
                >
                  <TertiaryArticle
                    articleLink={slug}
                    categoryLabel={subcategory.name}
                    header={title}
                    categoryLink={`${subcategory.category.slug}/${subcategory.slug}`}
                    image={thumbnailBam}
                  />
                </Col>
              </Hidden>
              <Visible sm xs>
                <Col sm={12} style={{ marginBottom: 10, padding: 0 }}>
                  <TertiaryArticleHorizontal
                    articleLink={slug}
                    header={title}
                    categoryLabel={subcategory.name}
                    categoryLink={`${subcategory.category.slug}/${subcategory.slug}`}
                    image={thumbnailBam}
                  />
                </Col>
              </Visible>
            </Hidden>
          );
        })}
      </Row>
    </Container>
  );
};

export default GridView;
