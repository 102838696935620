import React, { FunctionComponent } from 'react';
import './SectionMarker.scss';

export type SectionMarkerProps = {
  brandColor?:
    | 'brand-01'
    | 'brand-02'
    | 'brand-03'
    | 'brand-04'
    | 'brand-05'
    | 'brand-06';
};
const SectionMarker: FunctionComponent<SectionMarkerProps> = ({
  brandColor = 'brand-01',
}) => {
  return <div className={`section-marker ${brandColor}`} />;
};

export default SectionMarker;
