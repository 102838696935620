import { ImageNamesType } from 'components/Icon';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TIKTOK_URL,
  YOUTUBE_URL,
} from '../../../constants';
import React, { FunctionComponent } from 'react';
import { SocialIconLink } from '../SocialIconLink';

import './SocialCard.scss';

const SocialCard: FunctionComponent = () => {
  const socialLinks: [ImageNamesType, string, string][] = [
    ['facebook-original', 'Facebook', FACEBOOK_URL],
    ['youtube-original', 'Youtube', YOUTUBE_URL],
    ['tiktok-original', 'Tiktok', TIKTOK_URL],
    ['instagram-original', 'Instagram', INSTAGRAM_URL],
  ];

  return (
    <div className="social-card-container">
      <div className="title">Follow us</div>
      <div className="divider" />

      <div className="fb-like">
        <iframe
          title="facebook like button"
          src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FBeAmazedMedia%2F&width=235&layout=standard&action=like&size=large&share=false&height=35&appId=2663515493863710"
          width="100%"
          height="28"
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
      <div className="social-links">
        {socialLinks.map(([icon, alt, targetUrl]) => (
          <div className="social-link" key={alt}>
            <SocialIconLink icon={icon} alt={alt} targetURL={targetUrl} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialCard;
