import React, { FunctionComponent } from 'react';
import { ImageCldy } from '../ImageCldy';

import './TertiaryArticle.scss';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import * as routes from '../../../utils/routes';
export type TertiaryArticleProps = {
  image: ImageDataLike;
  header: string;
  categoryLabel?: string;
  articleLink: string;
  categoryLink?: string;
  isSponsored?: boolean;
};

const TertiaryArticle: FunctionComponent<TertiaryArticleProps> = ({
  image,
  header,
  categoryLabel,
  categoryLink,
  articleLink,
  isSponsored = false,
}) => {
  return (
    <div className="tertiary-article-container">
      <Link
        aria-label={`${header} article link`}
        to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
        className="article-img-container"
      >
        {image?.data?.target && (
          <ImageCldy
            title={image.data.target.fields.title['en-US']}
            description={image.data.target.fields.description['en-US']}
            file={image.data.target.fields.file['en-US']}
            objectFit="contain"
            className="article-img"
            size="thumbnail"
            showCaption={false}
          />
        )}
      </Link>
      {!isSponsored && categoryLink && categoryLabel && (
        <Link
          aria-label={`${categoryLabel} Category Link`}
          to={`${routes.SiteRoutes.CATEGORIES}/${categoryLink}`}
          className="category-label"
        >
          {categoryLabel}
        </Link>
      )}
      <Link
        to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
        className="article-header"
        aria-label={`${header} article link`}
      >
        {header}
      </Link>
      {isSponsored && <p className="informative-text">SPONSORED</p>}
    </div>
  );
};

export default TertiaryArticle;
