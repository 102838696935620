import React, { FunctionComponent } from 'react';

import './SectionHeaderTertiary.scss';

import { Divider } from '../Divider';
import { Icon, ImageNamesType } from '../Icon';

export type SectionHeaderTertiaryProps = {
  title: string;
  iconName: ImageNamesType;
  className?: string;
};

const SectionHeaderTertiary: FunctionComponent<SectionHeaderTertiaryProps> = ({
  title,
  iconName,
  className = '',
}) => {
  return (
    <div className={`section-container-tertiary ${className}`}>
      <div className="section-content-tertiary">
        <Icon
          name={iconName}
          alt={`icon ${title}`}
          className="section-container-tertiary-icon"
        />
        <p className="section-container-tertiary-title">{title}</p>
      </div>
      <Divider />
    </div>
  );
};

export default SectionHeaderTertiary;
