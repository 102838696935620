import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import * as routes from '../../../utils/routes';
import './FeatureArticle.scss';
import { ImageCldy } from '../ImageCldy';

export type FeatureArticleProps = {
  image: ImageDataLike;
  categoryLabel: string;
  header: string;
  categoryLink: string;
  articleLink: string;
};
const FeatureArticle: FunctionComponent<FeatureArticleProps> = ({
  image,
  categoryLabel,
  header,
  categoryLink,
  articleLink,
}) => {
  return (
    <div className="feature-article-container">
      <Link
        aria-label={`${header} article link`}
        to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
        className="article-img-container"
      >
        {image?.data?.target && (
          <ImageCldy
            title={image.data.target.fields.title['en-US']}
            description={image.data.target.fields.description['en-US']}
            file={image.data.target.fields.file['en-US']}
            objectFit="contain"
            objectPosition="left"
            className="article-img"
            size="medium"
            showCaption={false}
          />
        )}
      </Link>
      <Link
        to={`${routes.SiteRoutes.CATEGORIES}/${categoryLink}`}
        aria-label={`${categoryLabel} Category Link`}
        className="category-label"
      >
        {categoryLabel}
      </Link>
      <Link
        aria-label={`${header} article link`}
        to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
        className="article-header"
      >
        {header}
      </Link>
    </div>
  );
};

export default FeatureArticle;
