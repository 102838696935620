import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

import './TextLink.scss';

export type TextLinkProps = {
  variant: 'large' | 'small';
  to: string;
  ariaLabel: string;
};

const TextLink: FunctionComponent<TextLinkProps> = ({
  variant,
  to,
  children,
  ariaLabel,
}) => {
  return (
    <Link aria-label={ariaLabel} to={to} className={`text-link ${variant}`}>
      {children}
    </Link>
  );
};

export default TextLink;
