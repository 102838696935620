import { graphql, useStaticQuery } from 'gatsby';

export const useAllContentfulArticle = () => {
  const { allContentfulArticle } = useStaticQuery(
    graphql`
      query {
        allContentfulArticle(
          sort: { fields: publishedDate, order: DESC }
          filter: { subcategory: { id: { ne: null } } }
        ) {
          edges {
            node {
              id
              slug
              title
              subtitle
              subcategory {
                id
                name
                slug
                category {
                  id
                  name
                  slug
                  featuredOnHomePage
                  sectionMarkerColor
                  teaserText {
                    teaserText
                  }
                }
              }
              publishedDate(fromNow: true)
              thumbnailBam {
                data {
                  target {
                    fields {
                      title
                      description
                      file
                    }
                    sys {
                      id
                      linkType
                      type
                    }
                  }
                }
              }
              richTextBam {
                raw
              }
            }
          }
        }
      }
    `
  );
  return allContentfulArticle;
};
