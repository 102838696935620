import React, { FunctionComponent } from 'react';
import { Hidden, Visible, Container } from 'react-grid-system';

import { TitleIcon } from '../Title/Title';
import { SectionHeaderTertiary } from '../SectionHeaderTertiary';

import { TertiaryArticle } from '../TertiaryArticle';

import './SideArticleList.scss';

export type SideArticleListProps = {
  icon: TitleIcon;
  title: string;
  allContentfulArticle: any;
  articleCount?: number;
};

const SideArticleList: FunctionComponent<SideArticleListProps> = ({
  icon = 'categories-title-prefix',
  title,
  allContentfulArticle,
  articleCount = 10,
}) => {
  return allContentfulArticle.edges.length ? (
    <div className="side-article-list-container">
      <SectionHeaderTertiary iconName={icon} title={title} />

      <div className="article-container">
        <Container fluid style={{ padding: 0 }}>
          <Hidden md xs sm>
            {allContentfulArticle.edges
              .slice(0, articleCount)
              .map(({ node }, index) => {
                const { id, title, slug, thumbnailBam } = node;
                return (
                  <TertiaryArticle
                    key={id}
                    articleIndex={index + 1}
                    image={thumbnailBam}
                    header={title}
                    articleLink={slug}
                  />
                );
              })}
          </Hidden>

          <Visible md xs sm>
            <div className="mobile-view">
              {allContentfulArticle.edges
                .slice(0, 10)
                .map(({ node }, index) => {
                  const { id, title, slug, thumbnailBam } = node;
                  return (
                    <div key={id} className="item-container">
                      <TertiaryArticle
                        articleIndex={index + 1}
                        image={thumbnailBam}
                        header={title}
                        articleLink={slug}
                      />
                    </div>
                  );
                })}
            </div>
          </Visible>
        </Container>
      </div>
    </div>
  ) : null;
};

export default SideArticleList;
