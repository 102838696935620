import React, { FunctionComponent } from 'react';

import './SectionHeaderPrimary.scss';

import { Divider } from '../Divider';
import { SectionMarker, SectionMarkerProps } from '../SectionMarker';
import { TextLink } from '../TextLink';

export type SectionHeaderPrimaryProps = {
  title: string;
  description?: string;
  seeMoreLink?: string;
  sectionMarkerColor: SectionMarkerProps['brandColor'];
};
const SectionHeader: FunctionComponent<SectionHeaderPrimaryProps> = ({
  title,
  description,
  seeMoreLink,
  sectionMarkerColor,
}) => {
  return (
    <div className="section-container">
      <Divider />
      <div className="section-content">
        <div className="section-title-label-container">
          <SectionMarker brandColor={sectionMarkerColor} />
          <h1 className="section-title">{title}</h1>
          {description && <p className="section-description">{description}</p>}
        </div>
        {seeMoreLink && (
          <div className="text-link-container">
            <TextLink
              ariaLabel={`See More From ${title}`}
              variant="small"
              to={seeMoreLink}
            >
              More From {title}
            </TextLink>
          </div>
        )}
      </div>
      <Divider className="bottom-divider-root" />
    </div>
  );
};

export default SectionHeader;
