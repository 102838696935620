import React, { FunctionComponent } from 'react';

import './Divider.scss';

type DividerProps = {
  className?: string;
};

const Divider: FunctionComponent<DividerProps> = ({ className = '' }) => {
  return <hr className={`divider-root ${className}`} />;
};

export default Divider;
